import * as React from 'react';
import styled from 'styled-components';
import {colors, mq} from "../common/styles";
import {string} from "prop-types";


const StyledList = styled.div`
  display: flex;
  padding: 8px 0;
  flex-wrap: wrap;
  justify-content: center;
  // ${mq.laptop} {
  //  justify-content: initial;
  //}
  .item {
    letter-spacing: .75px;
    display: flex;
    align-items: center;
    &::after {
      content: "";
      ${mq.laptop} {
        content: "●";
      }
      font-size: 8px;
      color: ${colors.gunmetallight};     
      padding-right: 12px;
      padding-left: 12px;
    }
    &:last-of-type {
      &::after {
        display: none;
      }
    }
  }
`;


export default function ParagraphList({items}) {
  if (typeof items === 'string') {
    items = items.trim().split('\n').map(l => l.trim())
  }

  return (
      <StyledList>
        {items.map(d => (
          <div className='item' key={d}>{d}</div>
        ))}
	  </StyledList>
  )
}
