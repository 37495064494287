import * as React from 'react';
import styled from 'styled-components';
import PageContent from '../components/PageContent';
import PageGutters from '../components/PageGutters';
import PageContentSections from '../components/PageContentSections';
import Disclaimer from '../components/Disclaimer';
import Layout from '../components/Layout';
import CallToAction from "../components/CallToAction";
import {mq,colors} from "../common/styles";
import { PackageContainer, Package } from "../components/packages";
import { SubNavHeaderIds } from "../common/config";
import ParagraphList from '../components/ParagraphList';
import SectionImages from '../components/SectionImages';

const CenterH5Mobile = styled.h5`
  text-align: center;
  .price {
    padding-left: 4px;
    font-weight: 400;
    color: ${colors.gunmetallight};
  }
  // ${mq.laptop} {
  //  text-align: initial;
  //}
`;


const packageDetails = [{
    name: 'Have Your Cake and Eat It Too',
    price: '$3,500',
    details: `
        12 months Unlimited Email & Text with Sarah
        Monthly Consult with Sarah
        Full Access to Vendor Contact Lists
        Vendor POC Includes Day-of Coordination
        Custom Welcome Wedding Sign
        Timeline x2
        Full Set Up & Tear Down
    `,
}, {
    name: 'Eat, Drink, & Be Married',
    price: '$2,000',
    details: `
        6 months Unlimited Email & Text with Sarah
        Monthly Consult with Sarah
        Vendor POC Includes Day-Of Coordination
        Timeline
        Partial Set Up & Partial Tear Down
    `
}, {
    name: 'Tie The Knot',
    price: '$1,200',
    details: `
        1 month Unlimited Email & Text with Sarah
        Initial Consult
        Vendor POC Day-Of Only
        Partial Set Up & Partial Clean Up
    `,
}, {
    name: 'Get Hitched',
    price: '$600',
    details: `
        8 hours Of Service Day-Of
        Vendor POC
        Guest Flow Management
        Queue Bridal Party For Ceremony
        Cleanliness and Event Upkeep
        Partial Set Up & Clean Up
    `
}];


const eventList = `
Fundraiser
Dinner Party
Anniversary 
Graduation
Brunch
Surprise Proposal
Patio party
Date night
Picnic
Grand Opening`;

const weddingAddonList = `
Bridal Shower
Rehearsal Dinner
Bachelorette Party
Farewell Brunch
`;

const designList = `
Floral Crown 
Floral Hair Comb 
Bridal Bouquet 
Boutonniere 
Centerpieces
Wristlet 
Ceremony Arch Floral Arrangement`;

export function Services() {
  const Events = () => {
    return (
        <PageGutters>
            <p>
                There’s nothing better than bringing a group together over food and drink, no matter the occasion. However, for many of us, hosting a private party can feel like a lot of pressure, even if it’s just a small gathering. Once you dive into the details of what all goes into even a modest event, it’s clear why people choose to hire event planners for these things. Let Sarah help you successfully organize and host a fabulous event that you too will be able to enjoy!!
            </p>
            <ParagraphList items={eventList} />
            <SectionImages sectionName='events'/>
            <CallToAction
                to='/contact'
                actionText='Book an Event'
                primerText={`Let me help you make memories...`}
            />

        </PageGutters>
    )
  };
  const Weddings = () => {
    return (
        <div>
          <PageGutters>
            <p>
              Wedding planning services are for couples who are busy, full-time professionals with little to no extra time to devote to planning.  The purpose is to assist and guide you through the entire wedding planning process. Whether Sarah comes in from the beginning or halfway through
              she will help you select the most professional vendors, go through every detail, act as the point of person, keep everything organized, make sure nothing is forgotten, allowing you to enjoy your special day. Wedding planners are quite helpful and are often considered invaluable. Let Sarah help make certain that your wedding day is properly executed and goes off without a hitch!
            </p>
          </PageGutters>
          <PackageContainer>
            {packageDetails.map(p => <Package {...p} key={p.name} />)}
          </PackageContainer>
          <PageGutters>
              <CenterH5Mobile>
                  Popular Wedding Add-on Events
                  {/*<span className='price'> | $400</span>*/}
              </CenterH5Mobile>
            <ParagraphList items={weddingAddonList} />
          </PageGutters>
          <SectionImages sectionName='weddings'/>
          <CallToAction
            to='/contact'
            actionText='Book a Wedding Package'
            primerText={`Let me help you make memories...`}
          />
        </div>
    )
  };
  const Design = () => {
    return (
        <>
            <PageGutters>
                <p>
                  Nature is Sarah’s strongest inspiration when designing especially when creating with flowers. She notices the color palettes that just work so well together and the way the flowers naturally move. From lavishly styled bouquets, centerpieces, to boutonnieres, wristlets, and floral crowns Sarah sources the freshest, most pristine blooms available from local farms to ensure the highest quality of product for your event or wedding. Would you rather go the artificial route? With collective experience designing with both, Sarah is able to provide a variety of design styles to best suit all your design preferences.
                </p>
                <ParagraphList items={designList} />
            </PageGutters>
            <SectionImages sectionName='design'/>
            <CallToAction
                to='/contact'
                actionText='Book Design Services'
                primerText={`Let me help you make memories...`}
            />
        </>
      )
  };
  return (
    <Layout subPageTitle='Services'>
      <PageGutters>
          {/*<Salutation>It's all in the details...</Salutation>*/}
      </PageGutters>
      <PageContentSections
          contents={[{
            header: 'Events',
            headerId: SubNavHeaderIds.events,
            SectionContent: Events,
          }, {
            header: 'Weddings',
            headerId: SubNavHeaderIds.weddings,
            SectionContent: Weddings,
          }, {
            header: 'Design',
            headerId: SubNavHeaderIds.design,
            SectionContent: Design,
        }]}
      />
      <PageContent>
        <Disclaimer/>
      </PageContent>
    </Layout>
  );
}

export default Services;
